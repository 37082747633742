import React from 'react';
import './MainSection.css';

const MainSection = () => (
  <div className="main-section">
    <h2>Welcome to Excellent Staffing Solutions</h2>
    <p>Your partner in care provision</p>
    {/* More content as per the design */}
  </div>
);

export default MainSection;