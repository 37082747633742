import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../src/pages/HomePage'; // Path to your HomePage component
import './App.css'; // App-wide styles

function App() {
  return (
   <Router>
     <Routes>
        <Route path="/" element={< HomePage />} />
        {/* Other routes if you have more pages */}
    </Routes>
   </Router>
  );
}

export default App;