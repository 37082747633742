import React from 'react';
import Header from '../components/Header'; // Path to your Header component
import MainSection from '../components/MainSection'; // Path to your MainSection component
import Footer from '../components/Footer'; // Path to your Footer component
import './HomePage.css'; // Your HomePage specific styles if any
import Carousel from '../components/Carousel';

const images = [
  { url: '../assets/images/Home1.jpg', headerText: 'The Company',  bodyText: `At Excellent Solutions, we are committed to providing the highest level of service.` },
  { url: '../assets/images/Home2.jpg', headerText: 'Our Guiding Principles', bodyText: '' },
  { url: '../assets/images/Home3.jpg', headerText: 'Our Services', bodyText: '' },
  { url: '../assets/images/Home4.png', headerText: 'Our Commitment', bodyText: '' },
  { url: '../assets/images/Home5.png', headerText: 'Our Call', bodyText: 'We care' },
];

const HomePage = () => (



  <div className="home-page">
    <Header />
    <MainSection />
    <Carousel images={images} />
    <Footer />
  </div>
);

export default HomePage;