import React from 'react';
import PropTypes from 'prop-types';
import './FooterItem.css';

const FooterItem = ({ header, text, position }) => (
  <div className={`footer-item ${position}`}>
    <strong>{header}</strong>
    <div>{text}</div>
  </div>
);

FooterItem.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
};

export default FooterItem;