import React, { useState, useEffect } from 'react';
import './Carousel.css';

const CarouselItem = ({ headerText, bodyText, url, className }) => (
  <div className={`carousel-item ${className}`}>
    <div className="carousel-text">
      <h2>{headerText}</h2>
      <p>{bodyText}</p>
    </div>
    <div className="carousel-image" style={{ backgroundImage: `url(${url})` }}></div>
  </div>
);

const Carousel = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current === images.length - 1 ? 0 : current + 1));
    }, 10000); // Rotate images every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="carousel">
      {images.map((image, index) => (
        <CarouselItem 
          key={index}
          headerText={image.headerText} 
          bodyText={image.bodyText} 
          url={image.url} 
          className={index === activeIndex ? 'active' : ''} 
        />
      ))}
    </div>
  );
};

export default Carousel;