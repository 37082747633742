import React from 'react';
import './Footer.css';
import FooterItem from './FooterItem';

const Footer = () => (
  <footer className="footer">
    <FooterItem header="Address" text="Floor 8, Office 3 Peel St, Eccles, Manchester, England, M30 0NA" position="left" />
    <FooterItem header="Hours" text="Monday - Friday 9:00am - 5:00pm" position="center" />
    <FooterItem header="Email" text="info@excellentsolutions.co.uk" position="right" />
  </footer>
);

export default Footer;