import React from 'react';
import './Header.css'; // Make sure to import the CSS file

const linkStyle = {
  margin: '0 10px',
  textDecoration: 'none',
  padding: '10px 15px',
  borderRadius: '15px',
  backgroundColor: '#343a40',
  color: 'white',
  transition: 'background-color 0.3s, transform 0.1s',
  display: 'inline-block',
};

const Header = () => {
  return (
    <header className="header">
      <div className="brand-name">
        <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>EXCELLENT STAFFING SOLUTIONS</a>
      </div>
      <nav>
        <a href="/" style={linkStyle}>About Us</a>
        <a href="/" style={linkStyle}>Our Services</a>
        <a href="/" style={linkStyle}>Contact Us</a>
        <a href="/" style={linkStyle}>Enter Portal</a>
      </nav>
    </header>
  );
};
export default Header;